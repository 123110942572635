//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data () {
    return {
      checks: 1,
      money: "",
      radio: "1",
      IconWidth: 17,
      IconHeight: 17,

      list: [],
      loading: false,
      finished: false,
      moneys: 1000,
    };
  },

  methods: {
    checkss (e) {
      this.checks = e
      if (e == 1) {
        this.moneys = 1000;
      }
      if (e == 2) {
        this.moneys = 12000;
      }
      if (e == 3) {
        this.moneys = 6000;
      }
      if (e == 4) {
        this.moneys = 12000;
      }
      if (e == 5) {
        this.moneys = 24000;
      }
      if (e == 6) {
        this.moneys = 36000;
      }
    },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    funClick (e) {
      this.indexs = e;
    },
    // 提交
    onSubmit () { },
  },
};
